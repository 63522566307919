<template>
  <div class="message">
    <center-title title="消息详情" bottom="0"></center-title>
    <div class="parse" v-html="detail.content"></div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import MakePagination from "@/components/MakePagination";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
    "make-pagination": MakePagination,
  },
  data() {
    return {
      detail: {},
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 获取消息列表
    getNoticeList() {
      this.$api
        .getMessageDetailApi({
          id: this.$route.query.id,
        })
        .then((res) => {
          this.detail = res.data;
        });
    },
  },
  created() {
    this.getNoticeList();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.message-list {
  margin-bottom: 60px;

  .message-item {
    padding: 20px 0;
    border-bottom: $-solid-border;

    .item-pro {
      overflow: hidden;
      width: 80%;
      display: block;

      .pro-img {
        width: 87px;
        height: 72px;
        background-color: #ede2d4;
      }
    }
  }
}

.message {
  padding-bottom: 40px;
}

.parse {
  padding: 20px;

  /deep/ img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  .attr {
    ul {
      margin-bottom: 20px;

      &:after,
      &:before {
        clear: both;
        content: "";
      }

      li {
        width: 250px;
        //padding-left: 42px;
        float: left;
        margin-bottom: 5px;
      }
    }
  }
}
</style>
